<template>
    <div class="edu-top-header">
        <div class="edu-top-logo">
            <img class="logo" src="../../statics/images/logo.png">
            <div class="logo-tips">广东省妇女儿童活动中心<br/>网上报名系统</div>
        </div>
        <img class="edu-top-header-bg" src="../../statics/images/photo_top_bg.jpg">
    </div>
</template>
<script>
    export default {
        name: 'EduTopHeader',
    }
</script>
<style scoped>
    .edu-top-header {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
    }
    .edu-top-header-bg {
        width: 100%;
    }
    .edu-top-logo {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 20px;
    }
    .logo {
        width: 15%;
        height: auto;
    }
    .logo-tips {
        font-weight: bold;
        font-size: 18px;
        margin-left: 15px;
        color: white;
        text-shadow: 1px 1px 1px black;
        line-height: 28px;
    }
</style>
